import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Alegreya Sans", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#382E75",
    },
    secondary: {
      main: "#75b0bf",
    },
  },
});
